import Pristine from 'pristinejs';

let defaultConfig = {
    classTo: 'form-group',
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    errorTextParent: 'form-group',
    errorTextTag: 'div',
    errorTextClass: 'text-red-500 font-bold text-xs mt-2 mb-4'
};

Pristine.addMessages('nl', {
    required: "Dit veld is verplicht",
    email: "Vul een geldig E-mailadres in",
    tel: "Je telefoonnummer is verplicht",
    equals: "De waarden komen niet overeen",
    default: "Dit veld is verplicht"
})

Pristine.setLocale('nl')

const pristineDefaultForms = document.querySelectorAll('[data-pristine="default"]');
for (let i = 0; i < pristineDefaultForms.length; ++i) {
    let form = pristineDefaultForms[i];
    let pristine = new Pristine(form, defaultConfig, true);
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        const submitButton = form.querySelector('button[type="submit"]')
        let valid = pristine.validate()
        let successTitle = form.getAttribute('data-success-title')
        let successText = form.getAttribute('data-success-text')
        let successTitleClasses = form.getAttribute('data-success-title-classes')
        let successTextClasses = form.getAttribute('data-success-text-classes')
        if (valid) {
            submitButton.disabled = true
            if (form.hasAttribute('data-fetch')) {
                const formData = new FormData(form)
                fetch('/actions/contact-form/send', {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Accept': 'application/json', // ? without this header you won't get a valid JSON response from craft
                        // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
                    },
                    body: formData
                    // body: new URLSearchParams(formData)
                })
                    .then(response => response.json())
                    .then(data => {
                        let elementSuccessTitle = document.createElement("h2")
                        let elementSuccessTitleTextnode = document.createTextNode(successTitle)
                        elementSuccessTitle.appendChild(elementSuccessTitleTextnode)
                        elementSuccessTitle.className = successTitleClasses

                        let elementSuccessText = document.createElement("p")
                        let elementSuccessTextTextnode = document.createTextNode(successText)
                        elementSuccessText.insertAdjacentHTML('beforeend', successText)
                        elementSuccessText.className = successTextClasses

                        form.innerHTML = ""
                        form.appendChild(elementSuccessTitle)
                        form.appendChild(elementSuccessText)
                        if (dataLayer) {
                            dataLayer.push({ 'event': 'succes-'+form.id })
                        }
                    })
                    .catch((error) => {
                        console.error('Obaia Error:', error)
                        submitButton.disabled = false
                    })
                return false;
            }

            form.submit()
        }
    });
}