// ? These components function with alpine + tailwind UI components
// import Swiper, {Navigation, Pagination, Mousewheel, EffectCreative, EffectFade, Autoplay, FreeMode} from 'swiper'
import Swiper from 'swiper'
import {Navigation, Pagination, Mousewheel, EffectCreative, EffectFade, Autoplay, FreeMode} from 'swiper/modules'
import {Loader} from '@googlemaps/js-api-loader'
import {MarkerClusterer} from "@googlemaps/markerclusterer";

window.obaia.Components = {}

window.obaia.Components.tracker = () => {
    return {
        init() {

            setTimeout(()=>{
                console.log(typeof ga === 'function')
                console.log(typeof gtag === 'function')
                console.log("ga" in window)
                console.log("dataLayer" in window)
                window.dataLayer.push({
                        'event': 'aSpecificEventName',
                        'form_type_DL': 'contact_us'
                    });
                // console.log(typeof window.ga === 'function')
            },2000)
        },
        trackEvent(category, action, label) {
            // Check if the GA function is available
            if (typeof ga === 'function') {
                // GA is available, send the event
                // ga('send', 'event', 'Popup', 'Click','Step1');
                ga('send', 'event', category, action, label);
            } else {
                // GA is not available
                // Implement your fallback mechanism here
                console.log("GA is not available. Event not tracked:", category, action, label);
                // You might also consider queuing these events to try sending them later.
            }
        }
    }
}

window.obaia.Components.maps = (options) => {
    let markers = []
    let searchMarkers = []
    let markerCluster = null
    return {
        map: null,
        locations: window.obaia.Markers,
        radius: '',
        postcode: options.postcode ?? null,
        searchBox: null,
        renderer: null,
        // markers: [],
        // searchMarkers: [],
        // markerCluster: null,
        markerIcons: {
            gals: {default: '/assets/img/base/marker.svg', active: '/assets/img/base/marker-active.svg'},
            gents: {default: '/assets/img/base/marker-gents.svg', active: '/assets/img/base/marker-gents-active.svg'},
        },
        init() {
            const loader = new Loader({
                apiKey: window.obaia.api.googleMaps,
                version: "weekly",
                libraries: ["places", "geometry"]
            })
            const mapOptions = {
                center: {
                    lat: 52.1326,
                    lng: 5.2913,
                },
                zoom: 7.5,
                zoomControl: true,
                disableDefaultUI: true,
                styles: [{
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#E0B0A7"
                        },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "visibility": "on"
                        }]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#ff0015"
                        },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#ff0013"
                        },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "geometry.stroke",
                        "stylers": [{
                            "color": "#E0B0A7"
                        },
                            {
                                "saturation": 100
                            },
                            {
                                "lightness": 100
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "landscape",
                        "stylers": [{
                            "color": "#D39D93"
                        },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "road",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "water",
                        "stylers": [{
                            "color": "#E0B0A7"
                        },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ]
            }
            loader.load().then((google) => {

                const renderer = {
                    render: ({count, position}) =>
                        new google.maps.Marker({
                            label: {text: String(count), color: "white", fontSize: "14px"},
                            position,
                            icon: "/assets/img/base/marker.svg",
                            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                        }),
                }
                const linkedListWrapper = document.getElementById('obaia-maps-list')
                this.locations = window.obaia.Markers
                this.map = new google.maps.Map(document.getElementById("js-maps"), mapOptions)
                this.renderer = renderer

                const bounds = new google.maps.LatLngBounds()
                let geocoder = new google.maps.Geocoder()
                let geoXml = new geoXML3.parser({
                    map: this.map,
                    zoom: false,
                })
                geoXml.parse("/assets/kml/nld_adm0_inverted.kml")
                google.maps.event.addListener(geoXml, 'parsed', function () {
                    geocoder.geocode({
                        'address': "Netherlands"
                    }, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            // map.fitBounds(results[0].geometry.viewport);
                        } else {
                            alert("Geocode was not successful for the following reason: " + status);
                        }
                    })
                })

                // Render the markers on the map
                for (let i = 0; i < this.locations.length; i++) {
                    const marker = new google.maps.Marker({
                        position: new google.maps.LatLng(this.locations[i][1], this.locations[i][2]),
                        map: this.map,
                        icon: this.locations[i][3] ? this.markerIcons.gents.default : this.markerIcons.gals.default,
                        // zIndex: 999,
                        markerId: this.locations[i][0],
                        gents: this.locations[i][3]
                    })
                    bounds.extend(marker.getPosition())
                    google.maps.event.addListener(marker, 'click', () => {
                        this.toggleMarker(marker)
                        if (linkedListWrapper.querySelector('.is-current')) {
                            linkedListWrapper.querySelector('.is-current').classList.remove('is-current', '!opacity-100')
                        }
                        const targetElement = document.getElementById(this.locations[i][0])
                        const offset = targetElement.offsetTop
                        targetElement.classList.add('is-current', '!opacity-100')
                        linkedListWrapper.scrollTo({top: offset, behavior: 'smooth'})
                    })
                    markers.push(marker)
                }
                // map.fitBounds(bounds)
                // this.markerCluster = new MarkerClusterer({map: this.map, markers: markers, renderer: this.renderer})
                markerCluster = new MarkerClusterer({map: this.map, markers: markers, renderer: this.renderer})

                // SEARCH INPUT
                const searchInput = document.getElementById('maps-search')
                this.searchBox = new google.maps.places.SearchBox(searchInput)
            }).catch(e => {
                // do something
            })
        },

        searchRadius() {
            // const bounds = new google.maps.LatLngBounds()

            const places = this.searchBox.getPlaces()
            if (places.length === 0) {
                return;
            }
            this.clearSearchmarkers()
            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry")
                    return
                }

                const icon = {
                    url: place.icon,
                    // size: new google.maps.Size(50, 50),
                    // origin: new google.maps.Point(0, 0),
                    // anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(25, 25),
                }

                // Create a marker for each place.
                let markerWithRadius = new google.maps.Marker({
                    map: this.map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                })

                const circle = new google.maps.Circle({
                    strokeColor: "#000000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#000000",
                    fillOpacity: 0.35,
                    map: this.map,
                    center: markerWithRadius.center,
                    // radius: 6000
                    radius: parseFloat(this.radius ?? 10000)
                })

                circle.bindTo('center', markerWithRadius, 'position')

                searchMarkers.push(
                    [
                        markerWithRadius, circle
                    ]
                )

                // check if location markers are in radius of circle
                this.inRadius(circle)
            })
            // map.fitBounds(bounds)
        },

        inRadius(circle) {
            // const bounds = new google.maps.LatLngBounds()
            const markersInRadius = []
            for (let i = 0; i < markers.length; i++) {
                const marker = markers[i]
                let markerPosition = marker.getPosition()
                // if marker is within area
                if (google.maps.geometry.spherical.computeDistanceBetween(markerPosition, circle.getCenter()) <= circle.getRadius()) {
                    marker.setVisible(true)
                    markersInRadius.push(marker)
                    // bounds.extend(markerPosition)
                    document.getElementById(marker.markerId).style.display = 'block'
                } else {
                    marker.setVisible(false)
                    document.getElementById(marker.markerId).style.display = 'none'
                }
            }
            this.resetCluster(markersInRadius)
        },

        resetCluster(clusterMarkers) {
            markerCluster.clearMarkers()
            // markerCluster = new MarkerClusterer({map: this.map, markers: clusterMarkers, renderer: this.renderer})
        },

        resetMarkers() {
            for (let i = 0; i < markers.length; i++) {
                const marker = markers[i]
                this.clearSearchmarkers()
                marker.setVisible(true)
                // bounds.extend(marker.getPosition())
                // searchInput.value = ''
                this.radius = ''
                this.postcode = ''
                const obaiaMapsList = document.getElementById('obaia-maps-list')
                for (let i = 0; i < obaiaMapsList.children.length; i++) {
                    obaiaMapsList.children[i].style.display = 'block'
                }
                obaiaMapsList.scrollTo(0, 0)
            }
            // this.map.fitBounds(bounds)
            // TODO: bug fix hiervoor nodig, als je reset gaat dit systeem op z;n bek
            markerCluster.clearMarkers()
            // markerCluster = new MarkerClusterer({map: this.map, markers: markers, renderer: this.renderer})
        },

        clearSearchmarkers() {
            // Clear out the old marker + circle
            for (let i = 0; i < searchMarkers.length; i++) {
                const circleMarker = searchMarkers[i]
                circleMarker[0].setMap(null)
                circleMarker[1].setMap(null)
            }
        },

        findOnMap(markerId) {
            for (let i = 0; i < markers.length; i++) {
                if (markers[i].markerId === markerId) {
                    this.toggleMarker(markers[i])
                    this.map.panTo(markers[i].position)
                    this.map.setZoom(13);
                }
            }
        },

        toggleMarker(marker) {
            //reset all markers first
            for (let i = 0; i < markers.length; i++) {
                markers[i].setIcon(markers[i].gents ? this.markerIcons.gents.default : this.markerIcons.gals.default)
                markers[i].setZIndex(0)
            }
            marker.setIcon(marker.gents ? this.markerIcons.gents.active : this.markerIcons.gals.active)
            marker.setZIndex(999999)
        },
    }
}
// SWIPER
window.obaia.Components.swiper = (options) => {
    return {
        swiperConstance: '',
        init() {
            let initialSlideIndex = 0
            // if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
            //     initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
            // }
            if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                initialSlideIndex = parseFloat(options.initialSlide) - 1
            }
            const thisElem = this.$el
            // window.addEventListener('load', () => {
                this.swiperConstance = new Swiper(thisElem, {
                    modules: [Navigation, Pagination, Mousewheel, EffectCreative, EffectFade, Autoplay, FreeMode],
                    autoplay: options.autoplay ?? false,
                    speed: options.speed ?? 300,
                    freeMode: options.freeMode ?? false,
                    effect: options.effect ?? '',
                    fadeEffect: {
                        crossFade: true
                    },
                    // cssMode: options.cssMode ?? false,
                    grabCursor: options.grabCursor ?? false,
                    loop: options.loop ?? false,
                    autoHeight: options.autoHeight ?? false,
                    slidesPerView: options.slidesPerView ?? 1,
                    centeredSlides: options.centeredSlides ?? false,
                    spaceBetween: options.spaceBetween ?? 16,
                    // initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                    initialSlide: initialSlideIndex,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    navigation: {
                        prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                        nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                    },
                    // pagination: {
                    //     ...options.pagination
                    // },
                    pagination: typeof options.pagination === 'object' ? {...options.pagination} : {
                        el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
                        clickable: true,
                        renderBullet: function (index, className) {
                            let names = []
                            thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                                names.push(slide.getAttribute('data-name'))
                            })
                            return '<li class="cursor-pointer antialiased snap-center ' + className + '">' + names[index] + '</li>';
                        }
                    },
                    breakpoints: {
                        ...options.breakpoints
                    }
                })
                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'))
                }, 500)
            // })
        }
    }
},
    // window.obaia.Components.listbox = function (e) {
    //     return {
    //         init() {
    //             this.optionCount = this.$refs.listbox.children.length,
    //                 this.$watch("activeIndex", (e => {
    //                     this.open && (null !== this.activeIndex ? this.activeDescendant = this.$refs.listbox.children[this.activeIndex].id : this.activeDescendant = "")
    //                 }
    //                 ))
    //         },
    //         activeDescendant: null,
    //         optionCount: null,
    //         open: !1,
    //         activeIndex: null,
    //         selectedIndex: 0,
    //         get active() {
    //             return this.items[this.activeIndex]
    //         },
    //         get [e.modelName || "selected"]() {
    //             return this.items[this.selectedIndex]
    //         },
    //         choose(e) {
    //             this.selectedIndex = e,
    //                 this.open = !1
    //         },
    //         onButtonClick() {
    //             this.open || (this.activeIndex = this.selectedIndex,
    //                 this.open = !0,
    //                 this.$nextTick((() => {
    //                     this.$refs.listbox.focus(),
    //                         this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                             block: "nearest"
    //                         })
    //                 }
    //                 )))
    //         },
    //         onOptionSelect() {
    //             null !== this.activeIndex && (this.selectedIndex = this.activeIndex),
    //                 this.open = !1,
    //                 this.$refs.button.focus()
    //         },
    //         onEscape() {
    //             this.open = !1,
    //                 this.$refs.button.focus()
    //         },
    //         onArrowUp() {
    //             this.activeIndex = this.activeIndex - 1 < 0 ? this.optionCount - 1 : this.activeIndex - 1,
    //                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                     block: "nearest"
    //                 })
    //         },
    //         onArrowDown() {
    //             this.activeIndex = this.activeIndex + 1 > this.optionCount - 1 ? 0 : this.activeIndex + 1,
    //                 this.$refs.listbox.children[this.activeIndex].scrollIntoView({
    //                     block: "nearest"
    //                 })
    //         },
    //         ...e
    //     }
    // }
    window.obaia.Components.menu = function (e = {
        open: !1
    }) {
        return {
            init() {
                this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                    this.$watch("open", (() => {
                            this.open && (this.activeIndex = -1)
                        }
                    ))
            },
            activeDescendant: null,
            activeIndex: null,
            items: null,
            open: e.open,
            focusButton() {
                this.$refs.button.focus()
            },
            onButtonClick() {
                this.open = !this.open,
                this.open && this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                ))
            },
            onButtonEnter() {
                this.open = !this.open,
                this.open && (this.activeIndex = 0,
                    this.activeDescendant = this.items[this.activeIndex].id,
                    this.$nextTick((() => {
                            this.$refs["menu-items"].focus()
                        }
                    )))
            },
            onArrowUp() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = this.items.length - 1,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onArrowDown() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = 0,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onClickAway(e) {
                if (this.open) {
                    const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                    this.open = !1,
                    e.target.closest(t) || this.focusButton()
                }
            }
        }
    }

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}

window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
    return {
        value: void 0,
        active: void 0,
        init() {
            let t = Array.from(this.$el.querySelectorAll("input"));
            this.value = t[e]?.value;
            for (let e of t)
                e.addEventListener("change", (() => {
                        this.active = e.value
                    }
                )),
                    e.addEventListener("focus", (() => {
                            this.active = e.value
                        }
                    ));
            window.addEventListener("focus", (() => {
                    t.includes(document.activeElement) || (this.active = void 0
                    )
                }
            ), !0)
        }
    }
}
// window.obaia.Components.tabs = function () {
//     return {
//         selectedIndex: 0,
//         onTabClick(e) {
//             if (!this.$el.contains(e.detail))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))
//                 , n = t.indexOf(e.detail);
//             this.selectedIndex = n,
//                 window.dispatchEvent(new CustomEvent("tab-select", {
//                     detail: {
//                         tab: e.detail,
//                         panel: i[n]
//                     }
//                 }))
//         },
//         onTabKeydown(e) {
//             if (!this.$el.contains(e.detail.tab))
//                 return;
//             let t = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//                 , i = t.indexOf(e.detail.tab);
//             "ArrowLeft" === e.detail.key ? this.onTabClick({
//                 detail: t[(i - 1 + t.length) % t.length]
//             }) : "ArrowRight" === e.detail.key ? this.onTabClick({
//                 detail: t[(i + 1) % t.length]
//             }) : "Home" === e.detail.key || "PageUp" === e.detail.key ? this.onTabClick({
//                 detail: t[0]
//             }) : "End" !== e.detail.key && "PageDown" !== e.detail.key || this.onTabClick({
//                 detail: t[t.length - 1]
//             })
//         }
//     }
// }
// ,
// window.obaia.Components.tab = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tab("]'));
//             this.selected = t.indexOf(this.$el) === e,
//                 this.$watch("selected", (e => {
//                     e && this.$el.focus()
//                 }
//                 ))
//         },
//         onClick() {
//             window.dispatchEvent(new CustomEvent("tab-click", {
//                 detail: this.$el
//             }))
//         },
//         onKeydown(e) {
//             ["ArrowLeft", "ArrowRight", "Home", "PageUp", "End", "PageDown"].includes(e.key) && e.preventDefault(),
//                 window.dispatchEvent(new CustomEvent("tab-keydown", {
//                     detail: {
//                         tab: this.$el,
//                         key: e.key
//                     }
//                 }))
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.tab === this.$el
//         }
//     }
// }
// ,
// window.obaia.Components.tabPanel = function (e = 0) {
//     return {
//         selected: !1,
//         init() {
//             let t = Array.from(this.$el.closest('[x-data^="Components.tabs("]').querySelectorAll('[x-data^="Components.tabPanel("]'));
//             this.selected = t.indexOf(this.$el) === e
//         },
//         onTabSelect(e) {
//             this.selected = e.detail.panel === this.$el
//         }
//     }
// }