/**
 * This file kicks off Webpack's tasks to minify and compile CSS and JS.
 */
const flame = String.fromCodePoint(0x1f525)

// CSS imports
import './assets/css/main.css'

// JS imports
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'
import AOS from 'aos'
import './assets/js/_obaia-window-components'
import './assets/js/_obaia-window-helpers'
Alpine.plugin(mask)
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.start()
AOS.init()
// Components
import './assets/js/_validator-pristine'

// * END OF FILE
console.log(`%c
            
            `+flame+` Made with a keyboard by `+flame+`

 ________      ________      ________      ___      ________     
|\\   __  \\    |\\   __  \\    |\\   __  \\    |\\  \\    |\\   __  \\    
\\ \\  \\|\\  \\   \\ \\  \\|\\ /_   \\ \\  \\|\\  \\   \\ \\  \\   \\ \\  \\|\\  \\   
 \\ \\  \\\\\\  \\   \\ \\   __  \\   \\ \\   __  \\   \\ \\  \\   \\ \\   __  \\  
  \\ \\  \\\\\\  \\   \\ \\  \\|\\  \\   \\ \\  \\ \\  \\   \\ \\  \\   \\ \\  \\ \\  \\ 
   \\ \\_______\\   \\ \\_______\\   \\ \\__\\ \\__\\   \\ \\__\\   \\ \\__\\ \\__\\
    \\|_______|    \\|_______|    \\|__|\\|__|    \\|__|    \\|__|\\|__|


                            WEBDEVELOPMENT
                            https://obaia.nl

`, `font-family: monospace;`)